import React from "react";
import Seo from "../../../src/components/seo/index";
import Layout from "./layout/index";
import ImportFile from "./components/importFile";

const Upload = () => {
  return (
    <div>
      <Seo title="Green Heartfulness Run 2024" />
      <Layout isSticky>
        <ImportFile />
      </Layout>
    </div>
  );
};

export default Upload;
